<template>
  <div>
    <Header></Header>
    <div class="container">
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="16">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>临时文件</span>
              <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
            </div>
            {{ tempFilesRoot }}
            <el-image v-for="url in urls" :key="url" :src="url" lazy></el-image>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>添加新的上传</span>
              <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
            </div>
            <div v-for="o in 4" :key="o" class="text item">
              {{ '列表内容 ' + o }}
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'

export default {
  name: 'FilesScanner',
  components: { Header },
  data () {
    return {
      urls: [
        'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
        'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
        'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
      ],
      tempFilesRoot: [],
      tempImages: []
    }
  },
  created () {
    this.getAllTempFilesRoot()
  },
  methods: {
    getAllTempFilesRoot () {
      axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/tempFile/showMyTempFiles'
      }).then(res => {
        if (res.status) {
          console.log('res', res.data)
          this.tempFilesRoot = res.data.data
          this.getAllFiles()
        }
      })
    },
    getAllFiles () {
      this.tempFilesRoot.forEach(file => {
        console.log(file)
        const bucket = file.substring(9, 10)
        console.log(bucket)
        file = file.substring(11)
        console.log(file)
        file = file.substring(file.indexOf('.') + 1)
        console.log(file)
        file = file.substring(file.indexOf('.') + 1)
        console.log(file)
        const url = 'mooc-temp-' + bucket.toLowerCase() + '-1303830952.cos.ap-shanghai.myqcloud.com/' + file
        console.log(url)
      })
    }
  }
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
